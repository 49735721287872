import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
declare var zE: any;


@Injectable({
  providedIn: 'root',
})



export class ZendeskService {

  private isZendeskLoaded = false;

  // Declare 'zE' inside the class before using it

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.loadZendeskWidget();
  }
  // Load Zendesk Widget
  loadZendeskWidget() {
    if (isPlatformBrowser(this.platformId)) {
      const existingScript = document.getElementById('ze-snippet');

      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=840a0a7c-9c32-45fc-b207-3f705bd4fbc7';
        script.async = true;
        document.body.appendChild(script);

        // Wait for script to load and mark widget as loaded
        script.onload = () => {
          this.isZendeskLoaded = true;
          if (typeof zE !== 'undefined') {

            zE(function () {
              zE.hide();
            });


          }
        }
      } else {
        this.isZendeskLoaded = true; // Widget already loaded
      }
    }
  }

  // Activate Zendesk chat
  activateZendeskChat() {
    if (this.isZendeskLoaded && typeof zE !== 'undefined') {
      zE.activate();

    } else {
      console.error('Zendesk widget not loaded yet.');
    }
  }
}
