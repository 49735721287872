import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../../services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrl: './create-email.component.css'
})
export class CreateEmailComponent {
  isSubmitted: boolean = false; // Track submission status

  emailForm: FormGroup;
  selectedSubject: string = "N/A";
  showError: boolean = false;
  constructor(private router: Router, public dialogRef: MatDialogRef<CreateEmailComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private emailService: EmailService) {
    this.emailForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      companyName: ['', Validators.required],
      referal: ['Linkedin'],
      shipments: ['New to shipping'],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    this.isSubmitted = true; // Set submitted flag to true

    this.showError = this.selectedSubject === 'N/A';
    if (!this.showError && this.emailForm.valid) {
      this.emailForm.addControl('Subject', this.fb.control(this.selectedSubject));
      this.emailService.sendEmail(this.emailForm.value).subscribe({
        next: (response) => {
          this.dialogRef.close(this.data);
          this.router.navigate([{ outlets: { modal: ['downloadthankyou'] } }], {
            queryParams: { title: 'emailThankyou' }
          });
        },

        error: (error) => {
          this.snackBar.open(error.message, 'Failed', {
            duration: 3000,
            panelClass: ['snackbar-error'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        }
      });
    }
  }
  data(data: any) {
    throw new Error('Method not implemented.');
  }
  showShipments = false;
  onSelectionChange() {
    this.showError = false;
    if (this.selectedSubject == 'Sales') {
      this.showShipments = true;
    } else {
      this.showShipments = false;
    }
  }

  onCloseClick(data: any) {
    this.dialogRef.close(data);
  }
}
